import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Text from "../../../Atoms/Text";
import LinkSwitcher from "../../../Atoms/Link/LinkSwitcher";

const StandardPersonioDetails = ({ job, ctaHeadline, ctaTeaser, translations }) => {
  useEffect(() => {
    const firstAccordionItem = document.querySelector(
      ".accordion-item:first-child .accordion-button",
    );

    if (firstAccordionItem) {
      firstAccordionItem.click();
    }
  }, []);

  return (
    <>
      <h2 className="personio-details-job-name">{job.name}</h2>

      <div className="personio-details-job-description bg_color_primary mt-xlarge">
        <div className="teaser">
          <div className="row personio-details-job-description-item">
            <div className="col-md-6 col-lg-4">
              <div className="personio-details-job-description-title">{translations.office}</div>
            </div>
            <div className="col-md-6 col-lg-8 mt-3 mt-md-0">
              <div className="personio-details-job-description-content">{job.office}</div>
            </div>
          </div>
          <div className="row personio-details-job-description-item">
            <div className="col-md-6 col-lg-4">
              <div className="personio-details-job-description-title">
                {translations.subcompany}
              </div>
            </div>
            <div className="col-md-6 col-lg-8 mt-3 mt-md-0">
              <div className="personio-details-job-description-content">{job.subcompany}</div>
            </div>
          </div>
          {/* <div className="row personio-details-job-description-item">
            <div className="col-md-6 col-lg-4">
              <div className="personio-details-job-description-title">
                {translations.recruitingCategory}
              </div>
            </div>
            <div className="col-md-6 col-lg-8 mt-3 mt-md-0">
              <div className="personio-details-job-description-content">
                {job.recruitingCategory}
              </div>
            </div>
          </div> */}
          <div className="row personio-details-job-description-item">
            <div className="col-md-6 col-lg-4">
              <div className="personio-details-job-description-title">{translations.schedule}</div>
            </div>
            <div className="col-md-6 col-lg-8 mt-3 mt-md-0">
              <div className="personio-details-job-description-content">{job.schedule}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="personio-details-job-details mt-xlarge">
        <div className="accordion-flush" id={`accordion-${job.id}`}>
          {job.jobDescriptions?.jobDescription?.map((details, index) => (
            <div className="accordion-item">
              <h2 className="accordion-header" id={`flush-heading ${index}`}>
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#flush-collapse-${index}`}
                  aria-expanded="false"
                  aria-controls={`flush-collapse-${index}`}
                >
                  {details.name}
                </button>
              </h2>

              <div
                id={`flush-collapse-${index}`}
                className="accordion-collapse collapse"
                aria-labelledby={`flush-heading${index}`}
                data-bs-parent={`#accordion-${job.id}`}
              >
                <div
                  className="accordion-body"
                  dangerouslySetInnerHTML={{ __html: details.value }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {(ctaHeadline || ctaTeaser) && (
        <div className="personio-details-job-application mt-xlarge bg_color_main">
          <h3 className="personio-details-job-application-headline">{ctaHeadline}</h3>

          {ctaTeaser && (
            <div className="mt-xsmall">
              <Text textType="html" text={ctaTeaser} />
            </div>
          )}

          {job.applyLink && (
            <div className="mt-small">
              <div className="links">
                <LinkSwitcher
                  linkType="btn-primary"
                  link={{
                    target: "_blank",
                    title: translations.apply,
                    publicUrl: job.applyLink,
                    originalUrl: job.applyLink,
                  }}
                  preserveQueryString
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

StandardPersonioDetails.propTypes = {
  job: PropTypes.instanceOf(Object),
  ctaHeadline: PropTypes.string,
  ctaTeaser: PropTypes.string,
  translations: PropTypes.instanceOf(Object),
};

export default StandardPersonioDetails;
