import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const StandardHotspot = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    media,
    items,
    animation,
  },
}) => {
  const renderTabButton = useCallback(
    (item, index) => {
      const image = media[0];
      let imageWidth = 0;
      let imageHeight = 0;

      if (image) {
        imageWidth = image.properties?.dimensions?.width;
        imageHeight = image.properties?.dimensions?.height;
      }

      const imageLeftPosition = +item.x_coordinate
        ? `${(item.x_coordinate / imageWidth) * 100}%`
        : 0;
      const imageTopPosition = +item.y_coordinate
        ? `${(item.y_coordinate / imageHeight) * 100}%`
        : 0;

      const style = {
        left: imageLeftPosition,
        top: imageTopPosition,
      };

      return (
        <button
          key={`key_${index || Math.random()}`}
          className={`nav-link ${index ? "" : "active"}`}
          id={`nav-${index}-hotspot-tab`}
          data-bs-toggle="tab"
          data-bs-target={`#nav-${index}-hotspot`}
          type="button"
          role="tab"
          aria-controls={`nav-${index}-hotspot`}
          aria-selected={index ? "false" : "true"}
          style={style}
        >
          <div className="sr-only">{item.headline}</div>
        </button>
      );
    },
    [media],
  );

  const renderTabContent = useCallback(
    (item, index) => {
      return (
        <div
          key={`key_${index || Math.random()}`}
          className={`tab-pane fade show ${index ? "" : "active"} `}
          id={`nav-${index}-hotspot`}
          role="tabpanel"
          aria-labelledby={`nav-${index}-hotspot-tab`}
        >
          {item.subline && (
            <div data-aos={animation.preline.default}>
              <Preline preline={item.subline} />
            </div>
          )}

          {item.header && (
            <div data-aos={animation.headline.default}>
              <Headline headerType="h3" headline={item.header} />
            </div>
          )}

          {item.teaser && (
            <div className="mt-xxsmall" data-aos={animation.teaser.default}>
              <Text textType="html" text={item.teaser} />
            </div>
          )}

          {item.link && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={item.link_layout}
                  link={item.link}
                  // additionalClasses="btn-lg rounded-pill"
                />
              </div>
            </div>
          )}
        </div>
      );
    },
    [animation],
  );

  const renderHotspots = useCallback(() => {
    return (
      <div
        className="hotspot-dots-container"
        style={{
          width: 1290,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Image image={media[0]} additionalClasses="hotspot-image" />

        <nav className="hotspot-dots">
          <div className="nav nav-pills justify-content-center" id="nav-tab" role="tablist">
            {items.map((item, index) => renderTabButton(item, index))}
          </div>
        </nav>
      </div>
    );
  }, [items, media, renderTabButton]);

  const renderContent = useCallback(() => {
    return (
      <div className="tab-content" id="nav-tabContent">
        {items.map((item, index) => renderTabContent(item, index))}
      </div>
    );
  }, [items, renderTabContent]);

  return (
    <div className="container hotspot standard">
      <div className="row justify-content-center">
        <div className="col-md-10">
          {subline && (
            <div data-aos={animation.preline.default}>
              <Preline preline={subline} />
            </div>
          )}

          {header && (
            <div data-aos={animation.headline.default}>
              <Headline
                headerType={headerType}
                headerStyle={headerDisplayStyle}
                headline={header}
              />
            </div>
          )}

          {teaser && (
            <div className="mt-xsmall" data-aos={animation.teaser.default}>
              <Text textType="html" text={teaser} />
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>
      </div>

      {items.length > 0 && (
        <div className="row mt-medium">
          <div className="col-12 overflow-auto">{renderHotspots()}</div>
        </div>
      )}

      {items.length > 0 && (
        <div className="row mt-medium justify-content-center">
          <div className="col-md-10">{renderContent()}</div>
        </div>
      )}
    </div>
  );
};

StandardHotspot.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default React.memo(StandardHotspot);
