import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";

// eslint-disable-next-line import/no-cycle
import ElementSwitcher from "../ElementSwitcher";

const StandardTabs = ({
  properties: { header, headerType, headerDisplayStyle, subline, items, tabContent },
}) => {
  const renderTabButton = useCallback((label, id, index, tabTranslateUid) => {
    if (tabTranslateUid) {
      // eslint-disable-next-line no-param-reassign
      id = tabTranslateUid;
    }
    return (
      <button
        key={id}
        className={`nav-link ${index ? "" : "active"}`}
        id={`nav-${id}-tab`}
        data-bs-toggle="tab"
        data-bs-target={`#nav-${id}`}
        type="button"
        role="tab"
        aria-controls={`nav-${id}`}
        aria-selected={index ? "false" : "true"}
      >
        {label}
      </button>
    );
  }, []);

  const renderTabContent = useCallback(
    (id, index, tabTranslateUid) => {
      // eslint-disable-next-line no-param-reassign
      id = tabTranslateUid || id;
      return (
        <div
          key={id}
          className={`tab-pane fade show ${index ? "" : "active"} `}
          id={`nav-${id}`}
          role="tabpanel"
          aria-labelledby={`nav-${id}-tab`}
        >
          {tabContent.map((item) => {
            const compareID = item.tabTranslateUid ? item.tabTranslateUid : item.tabId;
            return (
              <React.Fragment key={item.id}>
                {compareID === id ? (
                  <ElementSwitcher contentElements={[item]} breadcrumbs={[]} isTabCycle />
                ) : (
                  <></>
                )}
              </React.Fragment>
            );
          })}
        </div>
      );
    },
    [tabContent],
  );

  const renderHeader = useCallback(() => {
    return (
      <nav>
        <div className="nav nav-pills justify-content-center" id="nav-tab" role="tablist">
          {/* eslint-disable-next-line max-len */}
          {items.map((item, index) =>
            renderTabButton(item.label, item.tabId, index, item.tabTranslateUid),
          )}
        </div>
      </nav>
    );
  }, [items, renderTabButton]);

  const renderContent = useCallback(() => {
    return (
      <div className="tab-content" id="nav-tabContent">
        {items.map((item, index) => renderTabContent(item.tabId, index, item.tabTranslateUid))}
      </div>
    );
  }, [items, renderTabContent]);

  return (
    <div className="container tabs-element standard">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {items.length > 0 && (
            <div className="mt-small">
              {renderHeader()}
              {renderContent()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

StandardTabs.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default StandardTabs;
