import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const SimpleStage = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    media,
    maxHeight,
    viewportHeight,
    mirrored,
    verticalAlignment,
    animation,
  },
}) => {
  const getClasses = useCallback(() => {
    let classes = "row flex-grow-1";

    switch (verticalAlignment) {
      case "top":
        classes += " align-items-start";
        break;
      case "center":
        classes += " align-items-center";
        break;
      case "bottom":
        classes += " align-items-end";
        break;
      default:
        classes += " align-items-center";
    }

    return classes;
  }, [verticalAlignment]);

  const getImageContainer = useCallback(() => {
    let inlineStyles = {
      position: "absolute",
      right: 0,
      top: 0,
      bottom: 0,
      width: "inherit",
      height: "100%",
      objectFit: "cover",
    };

    if (mirrored && mirrored !== "0") {
      inlineStyles = { ...inlineStyles, right: "auto", left: 0 };
    }

    return (
      <div className="col-lg-5 d-flex justify-content-center order-1 order-lg-2 mb-small mb-lg-0">
        <Image
          image={media[0]}
          style={inlineStyles}
          additionalClasses="stage-img"
          animation={animation.image.default}
        />
      </div>
    );
  }, [media, mirrored, animation]);

  const getTextContainer = useCallback(() => {
    return (
      <div className="col-lg-7 order-2 order-lg-1 mb-medium mb-lg-0">
        <div className="stage-text">
          {subline && (
            <div data-aos={animation.preline.default}>
              <Preline preline={subline} />
            </div>
          )}

          {header && (
            <div data-aos={animation.headline.default}>
              <Headline
                headerType={headerType}
                headerStyle={headerDisplayStyle}
                headline={header}
              />
            </div>
          )}

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="html" text={teaser} />
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }, [
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    animation,
  ]);

  const getContent = useCallback(() => {
    if (mirrored && mirrored !== "0") {
      return (
        <div className={`${getClasses()} flex-row-reverse mirrored`}>
          {getTextContainer()}
          {getImageContainer()}
        </div>
      );
    }

    return (
      <div className={getClasses()}>
        {getTextContainer()}
        {getImageContainer()}
      </div>
    );
  }, [mirrored, getImageContainer, getTextContainer, getClasses]);

  return (
    <div
      className={`container stage simple d-flex ${viewportHeight ? "min-vh-100" : ""}`.trim()}
      style={
        !viewportHeight ? { minHeight: +maxHeight || media[0]?.properties.dimensions.height } : {}
      }
    >
      {getContent()}
    </div>
  );
};

SimpleStage.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default SimpleStage;
