import React from "react";
import PropTypes from "prop-types";

import StandardPersonio from "./StandardPersonio";
import ComponentMissing from "../../Debug/ComponentMissing";

const Personio = ({
  layout,
  header,
  headerType,
  headerDisplayStyle,
  headerLink,
  headerLinkType,
  secondaryLink,
  secondaryLinkType,
  subline,
  teaser,
  jobs,
  companies,
  categories,
  ctaHeadline,
  ctaTeaser,
  animation,
}) => {
  const properties = {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    jobs,
    companies,
    categories,
    ctaHeadline,
    ctaTeaser,
    animation,
  };

  function switchPersonio() {
    switch (layout) {
      case "standard":
        return <StandardPersonio properties={properties} />;
      default:
        return <ComponentMissing type="Personio" subType={layout} />;
    }
  }

  return switchPersonio();
};

Personio.propTypes = {
  layout: PropTypes.string,
  header: PropTypes.string,
  headerType: PropTypes.string,
  headerDisplayStyle: PropTypes.string,
  headerLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  headerLinkType: PropTypes.string,
  secondaryLink: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  secondaryLinkType: PropTypes.string,
  subline: PropTypes.string,
  teaser: PropTypes.string,
  jobs: PropTypes.instanceOf(Array),
  companies: PropTypes.instanceOf(Array),
  categories: PropTypes.instanceOf(Array),
  ctaHeadline: PropTypes.string,
  ctaTeaser: PropTypes.string,
  animation: PropTypes.instanceOf(Object),
};

export default Personio;
