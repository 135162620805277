import React, { useRef, useCallback } from "react";
import PropTypes from "prop-types";

import SlickSlider from "react-slick";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const PrevArrow = (buttonProps) => {
  return (
    <span
      className={`carousel-control-prev-icon ${buttonProps.className}`}
      aria-hidden="true"
      onClick={buttonProps.onClick}
    />
  );
};

const NextArrow = (buttonProps) => {
  return (
    <span
      className={`carousel-control-next-icon ${buttonProps.className}`}
      aria-hidden="true"
      onClick={buttonProps.onClick}
    />
  );
};

/* eslint-disable react/jsx-props-no-spreading */
const SliderFeatures = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    subline,
    id,
    features,
    columnsPerRow,
    grayscale,
    viewportHeight,
    animation,
  },
}) => {
  const sliderRef = useRef(null);

  const settings = useRef({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: +columnsPerRow.col_lg,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: +columnsPerRow.col_md,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: +columnsPerRow.col_sm,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: +columnsPerRow.col_xs,
          slidesToScroll: 1,
        },
      },
    ],
  });

  const renderFeatures = useCallback(() => {
    return features.map((feature) => {
      let featureItem = (
        <div className="card w-100">
          <div className="card-body">
            <div className="card-image">
              {/* <Image image={feature.image[0]} animation={animation.image.default} /> */}
              <Image image={feature.image[0]} />
            </div>

            <div className={`${feature.image[0] ? "mt-xsmall" : ""}`.trim()}>
              <Preline preline={feature.subheader} additionalClasses="color-static" />

              <Headline
                headerType="h5"
                headline={feature.headline}
                additionalClasses="color-static"
              />
            </div>

            {feature.teaser && (
              <div className="mt-xsmall">
                <Text textType="html" text={feature.teaser} additionalClasses="color-static" />
              </div>
            )}

            {feature.link && (
              // <div className="mt-small" data-aos={animation.links.default}>
              <div className="mt-small">
                <div className="links">
                  <LinkSwitcher
                    linkType={feature.link_type}
                    link={feature.link}
                    additionalClasses="color-static"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      );

      if (feature.cardLink) {
        featureItem = (
          <a
            href={feature.cardLink.url}
            target={feature.cardLink.target}
            rel="noreferrer"
            className="d-flex card-link"
          >
            <div className="card w-100">
              <div className="card-body">
                <div className="card-image">
                  {/* <Image image={feature.image[0]} animation={animation.image.default} /> */}
                  <Image image={feature.image[0]} />
                </div>

                <div className={`${feature.image[0] ? "mt-xsmall" : ""}`.trim()}>
                  <Preline preline={feature.subheader} additionalClasses="color-static" />

                  <Headline
                    headerType="h5"
                    headline={feature.headline}
                    additionalClasses="color-static"
                  />
                </div>

                {feature.teaser && (
                  <div className="mt-xsmall">
                    <Text textType="html" text={feature.teaser} additionalClasses="color-static" />
                  </div>
                )}
              </div>
            </div>
          </a>
        );
      }
      return (
        <div
          key={`key_${feature.headline || Math.random()}`}
          className="carousel-item d-flex justify-content-center"
          data-aos={animation.features.default}
        >
          {featureItem}
        </div>
      );
    });
  }, [features, animation]);

  return (
    <div
      className={`container features slider ${grayscale ? "grayscale" : ""} ${
        viewportHeight
          ? "min-vh-100 d-flex flex-column justify-content-center pt-small pb-small"
          : ""
      }`.trim()}
    >
      <div className="row justify-content-center">
        <div className="col-md-10">
          {subline && (
            <div data-aos={animation.preline.default}>
              <Preline preline={subline} />
            </div>
          )}

          {header && (
            <div data-aos={animation.headline.default}>
              <Headline
                headerType={headerType}
                headerStyle={headerDisplayStyle}
                headline={header}
              />
            </div>
          )}
        </div>
      </div>

      {features.length > 0 && (
        <div className="row mt-small">
          <div className="col-12">
            <div ref={sliderRef} id={`features-slider-${id}`}>
              <SlickSlider {...settings.current}>{renderFeatures()}</SlickSlider>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SliderFeatures.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default SliderFeatures;
