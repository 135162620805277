import React, { useRef, useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";

import SlickSlider from "react-slick";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import Image from "../../Atoms/Image";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

const PrevArrow = (buttonProps) => {
  return (
    <span
      className={`carousel-control-prev-icon ${buttonProps.className}`}
      aria-hidden="true"
      onClick={buttonProps.onClick}
    />
  );
};

const NextArrow = (buttonProps) => {
  return (
    <span
      className={`carousel-control-next-icon ${buttonProps.className}`}
      aria-hidden="true"
      onClick={buttonProps.onClick}
    />
  );
};

/* eslint-disable react/jsx-props-no-spreading */
const SliderTimeline = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    timeline,
    animation,
  },
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderRef = useRef(null);

  const settings = useRef({
    dots: false,
    infinite: false,
    speed: 500,
    // slidesToShow: +columnsPerRow.col_lg,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    adaptiveHeight: true,
    beforeChange: (current, next) => setCurrentSlide(next),
    // responsive: [
    //   {
    //     breakpoint: 1199,
    //     settings: {
    //       slidesToShow: +columnsPerRow.col_md,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 991,
    //     settings: {
    //       slidesToShow: +columnsPerRow.col_sm,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 767,
    //     settings: {
    //       slidesToShow: +columnsPerRow.col_xs,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  });

  const adjustArrows = useCallback(() => {
    setTimeout(() => {
      const arrows = document.querySelectorAll(".slick-arrow");

      if (window.innerWidth <= 990) {
        const activeSlide = document.querySelector(".slick-current");
        const activeImage = activeSlide.querySelector(".timeline-image img");

        if (activeImage.height === 0) {
          adjustArrows();

          return;
        }

        arrows.forEach((arrow) => {
          // eslint-disable-next-line no-param-reassign
          arrow.style.top = `${activeImage.height / 2}px`;
        });
      } else {
        arrows.forEach((arrow) => {
          // eslint-disable-next-line no-param-reassign
          arrow.style.top = "";
        });
      }
    }, 250);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", adjustArrows);

    return () => {
      window.removeEventListener("resize", adjustArrows);
    };
  }, [adjustArrows]);

  useEffect(() => {
    adjustArrows();
  }, [currentSlide, adjustArrows]);

  useEffect(() => {
    const highLightedLabels = document.querySelectorAll(".timeline-line-item.highlighted");

    highLightedLabels.forEach((highLightedLabel) => {
      highLightedLabel.classList.remove("active");
    });

    const activeLabel = highLightedLabels[highLightedLabels.length - 1];

    activeLabel.classList.add("active");
  }, [currentSlide]);

  const renderTimelineCards = useCallback(() => {
    return timeline.map((date) => {
      return (
        <React.Fragment key={Math.random()}>
          <div className="timeline-card w-100">
            <div className="timeline-card-content">
              <div className="row justify-content-center align-items-center">
                {date.image[0] && date.imagePosition === "left" && (
                  <div className="col-lg-6">
                    <div className="timeline-image">
                      <Image image={date.image[0]} />
                    </div>
                  </div>
                )}

                <div className="col-lg-6 order-1">
                  {date.preline && (
                    <div className="mb-xxsmall">
                      <h5 className="timeline-date">{date.preline}</h5>
                    </div>
                  )}

                  {date.header && (
                    <div className="mb-xxsmall">
                      <Headline headerType="h4" headline={date.header} />
                    </div>
                  )}

                  <Text textType="html" text={date.contentText} />

                  {date.link && (
                    <div className="mt-small" data-aos={animation.links.default}>
                      <div className="links">
                        <LinkSwitcher linkType={date.link_type} link={date.link} />
                      </div>
                    </div>
                  )}
                </div>

                {date.image[0] && date.imagePosition === "right" && (
                  <div className="col-lg-6 order-0 order-lg-1">
                    <div className="timeline-image">
                      <Image image={date.image[0]} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    });
  }, [timeline, animation]);

  const renderTimelineLine = useCallback(() => {
    return timeline.map((date, index) => {
      const isHighlighted = currentSlide >= index;

      if (date.sectionLabel) {
        return (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li
            key={date.sectionLabel}
            className={`timeline-line-item ${isHighlighted ? "highlighted" : ""}`.trim()}
          >
            <button
              className="timeline-line-trigger"
              type="button"
              onClick={(e) => {
                e.preventDefault();

                setCurrentSlide(index);

                sliderRef.current.slickGoTo(index);
              }}
            >
              <span className="timeline-line-marker" />
              <span className="timeline-line-date">{date.sectionLabel}</span>
            </button>
          </li>
        );
      }

      return null;
    });
  }, [timeline, currentSlide]);

  return (
    // eslint-disable-next-line max-len
    <div className="container timeline timeline-slider d-flex flex-column justify-content-center">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <Preline preline={subline} />

          <Headline headerType={headerType} headerStyle={headerDisplayStyle} headline={header} />

          {teaser && (
            <div className="mt-xsmall">
              <Text textType="text" text={teaser} />
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>
      </div>

      {timeline.length > 0 && (
        <div className="mt-small">
          <div className="row justify-content-center">
            <div className="timeline-content">
              <div className="timeline-cards">
                <div>
                  <SlickSlider ref={sliderRef} {...settings.current}>
                    {renderTimelineCards()}
                  </SlickSlider>
                </div>
              </div>

              <div className="timeline-line-wrapper">
                <ul className="timeline-line mt-xlarge">{renderTimelineLine()}</ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SliderTimeline.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default SliderTimeline;
