/* eslint-disable react/no-this-in-sfc */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Preline from "../../Atoms/Preline";
import Headline from "../../Atoms/Headline";
import Text from "../../Atoms/Text";
import LinkSwitcher from "../../Atoms/Link/LinkSwitcher";

import "video.js/dist/video-js.css";

gsap.registerPlugin(ScrollTrigger);

const FullWidthScrollTransition = ({
  properties: {
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    media,
    maxHeight,
    viewportHeight,
    mirrored,
    verticalAlignment,
    animation,
  },
}) => {
  const mediaContainerRef = useRef(null);
  const textContainerRef = useRef(null);

  const getClasses = useCallback(() => {
    let classes = "row";

    switch (verticalAlignment) {
      case "top":
        classes += " align-items-start";
        break;
      case "center":
        classes += " align-items-center";
        break;
      case "bottom":
        classes += " align-items-end";
        break;
      default:
        classes += " align-items-center";
    }

    return classes;
  }, [verticalAlignment]);

  const getImageContainer = useCallback(() => {
    return (
      <div
        className="col-lg-6 d-flex justify-content-center order-1 order-lg-0 mb-4 mb-lg-0"
        ref={mediaContainerRef}
      >
        {/* <video
          className="scroll-transition-media-container"
          playsInline
          autoPlay
          preload="auto"
          muted={false}
          src={media[0].publicUrl || ""}
          // src="https://assets.codepen.io/39255/output_960.mp4"
        /> */}

        <canvas className="scroll-transition-media-container" />
      </div>
    );
  }, []);

  const getTextContainer = useCallback(() => {
    return (
      <div className="col-lg-6 order-2 order-lg-0" ref={textContainerRef}>
        <div className="scroll-transition-text-container">
          {subline && (
            <div data-aos={animation.preline.default}>
              <Preline preline={subline} />
            </div>
          )}

          {header && (
            <div data-aos={animation.headline.default}>
              <Headline
                headerType={headerType}
                headerStyle={headerDisplayStyle}
                headline={header}
              />
            </div>
          )}

          {teaser && (
            <div className="mt-xsmall" data-aos={animation.teaser.default}>
              <Text textType="html" text={teaser} />
            </div>
          )}

          {(headerLink || secondaryLink) && (
            <div className="mt-small" data-aos={animation.links.default}>
              <div className="links">
                <LinkSwitcher
                  linkType={headerLinkType}
                  link={headerLink}
                  // additionalClasses="btn-lg rounded-pill"
                />

                <LinkSwitcher linkType={secondaryLinkType} link={secondaryLink} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }, [
    header,
    headerType,
    headerDisplayStyle,
    headerLink,
    headerLinkType,
    secondaryLink,
    secondaryLinkType,
    subline,
    teaser,
    animation,
  ]);

  const getContent = useCallback(() => {
    if (mirrored && mirrored !== "0") {
      return (
        <div className={`${getClasses()} flex-row-reverse mirrored`}>
          {getImageContainer()}
          {getTextContainer()}
        </div>
      );
    }

    return (
      <div className={getClasses()}>
        {getImageContainer()}
        {getTextContainer()}
      </div>
    );
  }, [mirrored, getClasses, getImageContainer, getTextContainer]);

  useEffect(() => {
    // const video = mediaContainerRef.current.querySelector(".scroll-transition-media-container");

    const canvas = mediaContainerRef.current.querySelector(".scroll-transition-media-container");
    const context = canvas.getContext("2d");

    canvas.width = 1158;
    canvas.height = 770;

    const frameCount = media.length;

    const imagesArray = [];

    const promisesArray = [];

    const framesObject = {
      frame: 0,
    };

    const render = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(imagesArray[framesObject.frame], 0, 0);
    };

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < frameCount; i++) {
      promisesArray.push(
        new Promise((resolve) => {
          const img = new Image();

          img.onload = function () {
            if (i === 0) {
              canvas.width = this.width;
              canvas.height = this.height;

              render();
            }

            resolve();
          };

          img.src = media[i].publicUrl;

          imagesArray.push(img);
        }),
      );
    }

    // imagesArray[0].onload = function () {
    //   canvas.width = this.width;
    //   canvas.height = this.height;

    //   render();
    // };

    const initVideoScrub = () => {
      // video.removeEventListener("canplaythrough", initVideoScrub);

      // // Firefox fix
      // video.pause();
      // video.currentTime = 0;

      ScrollTrigger.matchMedia({
        "(max-width: 991px)": function () {
          // gsap.to(video, {
          gsap.to(framesObject, {
            // currentTime: video.duration,
            frame: frameCount - 1,
            // ease: "none",
            snap: "frame",
            x: "-30%",
            y: "-30%",
            scale: 1.75,
            scrollTrigger: {
              scrub: true,
              // markers: true,
              // markers: {
              //   startColor: "green",
              //   endColor: "green",
              // },
              // trigger: video,
              trigger: canvas,
              // start: "top bottom-=10%",
              // end: "bottom bottom-=10%",
              start: "bottom bottom",
              end: "top top+=20%",
            },
            onUpdate: render,
          });

          gsap.to(canvas, {
            x: "-30%",
            y: "-30%",
            scale: 1.75,
            scrollTrigger: {
              scrub: true,
              // markers: true,
              // markers: {
              //   startColor: "green",
              //   endColor: "green",
              // },
              // trigger: video,
              trigger: canvas,
              // start: "top bottom-=10%",
              // end: "bottom bottom-=10%",
              start: "bottom bottom",
              end: "top top+=20%",
            },
          });
        },
        "(min-width: 992px)": function () {
          // gsap.to(video, {
          gsap.to(framesObject, {
            // currentTime: video.duration,
            frame: frameCount - 1,
            // ease: "none",
            snap: "frame",
            scrollTrigger: {
              scrub: true,
              // markers: true,
              // markers: {
              //   startColor: "green",
              //   endColor: "green",
              // },
              // trigger: video,
              trigger: canvas,
              // start: "top bottom-=10%",
              // end: "bottom bottom-=10%",
              start: "center+=5% bottom",
              end: "center+=30% center+=15%",
            },
            onUpdate: render,
          });
        },
      });
    };

    // if (!/Firefox[/\s](\d+\.\d+)/.test(navigator.userAgent)) {
    //   if (video.readyState > 3) {
    //     initVideoScrub();
    //   } else {
    //     video.addEventListener("canplaythrough", initVideoScrub);
    //   }
    // } else {
    //   video.autoPlay = true;
    //   video.muted = false;
    //   video.loop = true;
    // }

    Promise.all(promisesArray).then(() => {
      initVideoScrub();
      ScrollTrigger.refresh();
    });

    const initTextScrub = () => {
      ScrollTrigger.matchMedia({
        "(max-width: 991px)": function () {
          gsap.from(textContainerRef.current.querySelector(".scroll-transition-text-container"), {
            y: 50,
            opacity: 0,
            scale: 0.75,
            scrollTrigger: {
              scrub: true,
              // markers: true,
              // markers: {
              //   startColor: "red",
              //   endColor: "red",
              // },
              trigger: textContainerRef.current,
              // start: "top center-=10px",
              // end: "bottom center+=10px",
              // start: "top bottom-=20%",
              // end: "bottom bottom-=20%",
              start: "top+=10% bottom",
              end: "top top+=10%",
            },
          });
        },
        "(min-width: 992px)": function () {
          gsap.from(textContainerRef.current.querySelector(".scroll-transition-text-container"), {
            y: 50,
            opacity: 0,
            scale: 0.75,
            scrollTrigger: {
              scrub: true,
              // markers: true,
              // markers: {
              //   startColor: "red",
              //   endColor: "red",
              // },
              trigger: textContainerRef.current,
              // start: "top center-=10px",
              // end: "bottom center+=10px",
              // start: "top bottom-=20%",
              // end: "bottom bottom-=20%",
              start: "top-=20% center",
              end: "top-=20% top+=10%",
            },
          });
        },
      });
    };

    initTextScrub();

    window.addEventListener("resize", () => {
      ScrollTrigger.refresh();
    });

    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 1000);
  }, [media]);

  return (
    <div
      className={`scroll-transition full-width d-flex ${
        viewportHeight ? "min-vh-100 pt-xlarge pb-xlarge" : "pt-xlarge pb-xlarge"
      }`.trim()}
      style={
        !viewportHeight ? { minHeight: +maxHeight || media[0]?.properties.dimensions.height } : {}
      }
    >
      <div className="container d-flex">{getContent()}</div>
    </div>
  );
};

FullWidthScrollTransition.propTypes = {
  properties: PropTypes.instanceOf(Object),
};

export default FullWidthScrollTransition;
