import React from "react";
import PropTypes from "prop-types";

const StandardPersonioList = ({ filteredJobs, setSelectedJob, translations }) => {
  return Object.keys(filteredJobs).map((category, index) => {
    return (
      <div
        key={`key_${category}`}
        className={`personio-list-jobs-wrapper ${index === 0 ? "mt-medium" : ""}`.trim()}
      >
        <h3 className="personio-list-jobs-category d-none">{category}</h3>

        <ul className="personio-list-jobs">
          {filteredJobs[category].map((job) => {
            return (
              <li key={`key_${job.id}`} className="personio-list-jobs-item">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();

                    setSelectedJob(job);

                    // document.querySelector(".personio.standard")?.scrollIntoView();

                    const wrapper =
                      document.querySelector(".personio.standard")?.getBoundingClientRect().top +
                      window.scrollY -
                      120;

                    window.scrollTo({ top: wrapper });
                  }}
                >
                  <span> {job.name} </span>

                  {translations.jobDetails}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  });
};

StandardPersonioList.propTypes = {
  filteredJobs: PropTypes.instanceOf(Object),
  setSelectedJob: PropTypes.func,
  translations: PropTypes.instanceOf(Object),
};

export default StandardPersonioList;
